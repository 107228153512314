<template>
  <v-layout column shrink>
    <v-tooltip bottom :disabled="enableUpdateFieldButtons">
      <template v-slot:activator="{ on, attrs }">
        <v-layout shrink v-on="on" v-bind="attrs">
          <CardSpectatorMenuButton
            :disabled="!enableUpdateFieldButtons"
            @clicked="handleClickMenuButton"
          ></CardSpectatorMenuButton>
        </v-layout>
      </template>
      <span>{{ updateFieldButtonToolip }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-layout shrink v-on="on" v-bind="attrs">
          <DefaultButton
            icon
            @click.prevent="handleClickCopy"
            class="mt-2 mb-0 mx-0"
            bgColor="transparent"
            color="primary"
          >
            <v-icon>far fa-copy</v-icon>
          </DefaultButton>
        </v-layout>
      </template>
      <span>Kliknij by skopiować wpis do schowka</span>
    </v-tooltip>
  </v-layout>
</template>

<script>
export default {
  props: {
    enableUpdateFieldButtons: {
      default: false,
    },
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    CardSpectatorMenuButton: () => import("../menus/CardSpectatorMenuButton"),
  },
  computed: {
    updateFieldButtonToolip() {
      if (!this.enableUpdateFieldButtons) {
        return "Rozpocznij wizytę w swojej karcie pacjenta by móc dodac ten wpis.";
      } else {
        return "";
      }
    },
  },
  methods: {
    handleClickMenuButton(value) {
      switch (value) {
        case 0:
          this.$emit("addRecord");
          return;
        case 1:
          this.$emit("addRecord");
          return;
      }
    },
    handleClickCopy() {
      this.$emit("copyRecord");
    },
  },
};
</script>